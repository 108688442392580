<template>
  <article>
    <header class="tabs-header">
      <ul class="tabs">
        <li
          class="tab-item"
          v-for="(tab, index) in tabs"
          :key="index"
          @click="toggleTab(tab)"
        >
          <b-card no-body bg-variant="primary" class="tab-icon">
            <feather-icon icon="EditIcon" />
          </b-card>
          
          <div class="tab-title no-select" :class="{ isActive: tab.isActive }">
            {{ tab.name }}
          </div>
          
          <b-card no-body class="next-icon">
            <feather-icon icon="ChevronRightIcon" v-if="(index < tabs.length-1)" />
          </b-card>

        </li>
      </ul>
    </header>

    <section class="tab-detail">
      <slot></slot>
    </section>
  </article>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { ChevronRightIcon } from 'vue-feather-icons'

export default {
  components: {
    FeatherIcon,
    ChevronRightIcon
  },
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    toggleTab(tab) {
      this.tabs.forEach((tabItem, index) => {
        tabItem.isActive = tab === tabItem ? true : false;
      });
    },
  },
};
</script>

<style>
.tabs-header {
  margin: 0 0 15px 0;
  display: flex;
  padding: 5px 0 0 2px;
}

.tab-detail {
  padding: 10px;
}

.isActive {
  color: black !important;
  font-weight: 600;
}

.tabs {
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.tab-item {
  display: flex;
  padding: 6px 15px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tab-icon{
  height: 30px;
  width: 30px;
  color: white;
  padding: 7px;
  margin: 0 13px 0 0;
}

.next-icon {
  padding: 7px 0;
  box-shadow: none;
  margin: 0;
}

.tab-title{
  height: 30px;
  line-height: 30px;
  margin-right: 15px;
}

.no-select {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>