import useJwt from "@/auth/jwt/useJwt";

class CatalogDataManager {
  constructor() {}
  
  //For singleton
  static instance;

  static getInstance() {
    if(!CatalogDataManager.instance) {
        CatalogDataManager.instance = new CatalogDataManager()
    }
    return CatalogDataManager.instance
  }

  // api call function
  loadCatalogDataWithID(id, serverParams, success){
    switch (id) {
      case 'catalog_type':
        this.loadCatalogType(serverParams, success)
        break
      case 'catalog_fields':
        this.loadCatalogField(serverParams, success)
        break
      case 'field_detail':
        this.loadCatalogFieldDetail(serverParams, success)
        break
      case 'catalog_ranges':
        this.loadCatalogRanges(serverParams, success)
        break
      case 'catalog_sources':
        this.loadCatalogSource(serverParams, success)
        break
      case 'catalog_career':
        this.loadCatalogCareers(serverParams, success)
        break
      case 'catalog_signer':
        this.loadCatalogSigners(serverParams, success)
        break
      case 'signer_position':
        this.loadSignersPosition(serverParams, success)
        break
      case 'issue_place':
        this.loadCatalogIssuedPlace(serverParams, success)
        break
      case 'catalog_wdetail_issued':
        this.loadCatalogIssuedPlaceDetail(serverParams, success)
        break
      case 'catalog_status':
        this.loadCatalogStatus(serverParams, success)
      default:
        break
    }
  }

  loadCatalogType(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_types", serverParams, success)
  }

  loadCatalogRanges(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_ranges", serverParams, success)
  }

  loadCatalogField(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_fields", serverParams, success)
  }

  loadCatalogFieldDetail(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_fdetails", serverParams, success)
  }

  loadCatalogSource(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_sources", serverParams, success)
  }

  loadCatalogCareers(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_careers", serverParams, success)
  }

  loadCatalogSigners(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_signers", serverParams, success)
  }

  loadSignersPosition(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_positions", serverParams, success)
  }

  loadCatalogIssuedPlace(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_where_issued", serverParams, success)
  }

  loadCatalogIssuedPlaceDetail(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_wdetail_issued", serverParams, success)
  }

  loadCatalogStatus(serverParams, success) {
    this.loadDataWithURL("getComboData/catalog_status", serverParams, success)
  }

  loadDataContents(serverParams, success) {
    this.loadDataWithURL('getComboData/data_contents', serverParams, success)
  }

  loadDataWithURL(url, serverParams, success) {
    useJwt
      .post(url, serverParams)
      .then((response) => {
        let result = response.data.result;
        result.forEach((i) => {
          i.text = i.name;
        });
        success(result)
      });
  }
}

export default CatalogDataManager;