<template>
<div>
  <b-card no-body>
    <div class="m-2">
          <b-row>
              <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                    <b-form-group label= "Loại văn bản">
                      <Select2
                          :state="errors.length > 0 ? false:null"
                          placeholder="Chọn loại văn bản"
                          v-model="serverParams.columnFilters.loaivb_id"
                          :options="list_loaivbs"
                        />
                    </b-form-group>
                </validation-provider>
          </b-col>

           <b-col cols="12" md="3">
            <b-form-group label="Số hiệu văn bản">
              <b-form-input v-model="serverParams.columnFilters.sohieu_vb"/>
            </b-form-group>
          </b-col>

        <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                      <b-form-group label="Ngày ban hành từ">
                          <b-form-datepicker
                              :state="errors.length > 0 ? false:null"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              locale="vi-VN"
                              v-model="serverParams.columnFilters.process_date_from"
                            />
                            <template #append>
                              <b-button variant="outline-secondary" class="btn-icon" @click="serverParams.columnFilters.process_date_from = ''"><feather-icon icon="XIcon"/></b-button>
                            </template>
                      </b-form-group>
                </validation-provider>
            </b-col>
            <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                      <b-form-group label="Ngày ban hành đến" >
                          <b-form-datepicker
                              :state="errors.length > 0 ? false:null"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              locale="vi-VN"
                              v-model="serverParams.columnFilters.process_date_to"
                            />
                            <template #append>
                              <b-button variant="outline-secondary" class="btn-icon" @click="serverParams.columnFilters.process_date_to = ''"><feather-icon icon="XIcon"/></b-button>
                            </template>
                      </b-form-group>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row>

          <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                    <b-form-group label= "Nơi ban hành">
                      <Select2
                          :state="errors.length > 0 ? false:null"
                          placeholder="Chọn nơi ban hành"
                          v-model="serverParams.columnFilters.noi_banhanh"
                          :options="list_loaivbs"
                        />
                    </b-form-group>
                </validation-provider>
          </b-col>

          
            <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                    <b-form-group label= "Lĩnh vực">
                      <Select2
                          :state="errors.length > 0 ? false:null"
                          placeholder="Chọn lĩnh vực"
                          v-model="serverParams.columnFilters.linhvuc_id"
                          :options="list_linhvucs"
                        />
                    </b-form-group>
                </validation-provider>
          </b-col>

            <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                    <b-form-group label= "Lĩnh vực chi tiết">
                      <Select2
                          :state="errors.length > 0 ? false:null"
                          placeholder="Chọn lĩnh vực chi tiết"
                          v-model="serverParams.columnFilters.linhvucd_id"
                          :options="list_loaivbs"
                        />
                    </b-form-group>
                </validation-provider>
          </b-col>
          <b-col cols="12" md="3">
              <validation-provider #default="{ errors }" rules="">
                    <b-form-group label= "Ngành nghề">
                      <Select2
                          :state="errors.length > 0 ? false:null"
                          placeholder="Chọn ngành nghề"
                          v-model="serverParams.columnFilters.nganh"
                          :options="list_loaivbs"
                        />
                    </b-form-group>
                </validation-provider>
          </b-col>
          
        
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
            <b-form-group label="Trích yếu văn bản">
              <b-form-input v-model="serverParams.columnFilters.title"/>
            </b-form-group>
          </b-col>
        </b-row>

        
        <div v-if="isAdvance==true" > 
              <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group label="Người ký">
                      <b-form-input v-model="serverParams.columnFilters.nguoi_ky"/>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3">
                     <validation-provider #default="{ errors }" rules="">
                        <b-form-group label= "chức vụ người ký">
                          <Select2
                              :state="errors.length > 0 ? false:null"
                              placeholder="Chọn chức vụ người ký"
                              v-model="serverParams.columnFilters.cv_nguoi_ky"
                              :options="list_chucvus"
                            />
                        </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-form-group label="Ngày công báo">
                      <b-form-input v-model="serverParams.columnFilters.ngay_congbao"/>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3">
                     <validation-provider #default="{ errors }" rules="">
                        <b-form-group label= "Tình trạng">
                          <Select2
                              :state="errors.length > 0 ? false:null"
                              placeholder="Chọn tình trạng"
                              v-model="serverParams.columnFilters.cv_nguoi_ky"
                              :options="list_chucvus"
                            />
                        </b-form-group>
                    </validation-provider>
                  </b-col>
                  
              </b-row>
        </div>
    <div class="m-1"></div>
      <b-row>
          <b-col cols="12" class="text-center">
            <b-button variant="secondary" @click="$router.go($router.currentRoute)" > Xoá trắng </b-button>
            <b-button variant="primary" class="ml-1" @click="updateAdvance" > Tìm kiếm nâng cao </b-button>
            <b-button variant="primary" class="ml-1" @click="searchInf()" > Tìm kiếm </b-button>
          </b-col>
      </b-row>
    </div>
  </b-card>
  
  <b-card no-body> 
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="pb-1">
        <b-col cols="8">
          <h4>Danh sách văn bản</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button
            variant="primary"
            @click="New()" class="btn-sm">
            <feather-icon  icon="PlusIcon" size="16" class="align-text-top"/> Thêm mới
          </b-button>
        </b-col>
        <!-- Per Page -->
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table :columns="fields" :rows="items" :line-numbers="true" :pagination-options="{enabled: true}"
            :totalRows="totalRecords"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :isLoading.sync="isLoading"

          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <b-button
                  variant="primary" class="btn-icon" size="sm" @click="Edit(props.row)">
                  <feather-icon icon="Edit2Icon"/>
                </b-button>
                <b-button
                  variant="danger"
                  class="btn-icon"
                  style="margin-left:6px"
                  size="sm"
                  @click="Delete(props.row,props.row.id)">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
                
              </span>
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.pageSize"
                    :options="['50','100','500']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ items.length }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="serverParams.pageSize"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>

      </b-row>

    </div>
    
  </b-card>


   <b-modal size="xl" id="modal-edit" :title="'Chi tiết nội dung văn bản'" :hide-footer="true">
      <crud-content :isHistory="false" :type="type" :data="currentItem" :rows="detail_rows"
        @close="$bvModal.hide('modal-view')" 
      />
    </b-modal>

  
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { BFormDatepicker, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip,BFormSelect } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-good-table.scss';
import Select2 from 'v-select2-component';
import CrudContent from './CrudContent.vue'


export default {
  components: {
    BFormDatepicker,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,ValidationProvider, ValidationObserver,BFormSelect,VueGoodTable,Select2,
    CrudContent
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.loadInitData();
    
  },
  watch: {
    $route(to, from) {
      this.loadInitData();
    }
  },
  methods: {
    loadInitData(){   
      useJwt.post('getComboData/catalog_types',this.serverParams).then(response =>{this.list_loaivbs = response.data.result;
        console.log(this.list_loaivbs); 
          //
          console.log("debug..............:> thent");
          this.list_loaivbs.forEach(i=>{i.text = i.name;})
          
          });
      useJwt.post('getMasterData/catalog_fields',this.serverParams).then(response =>{ this.list_linhvucs = response.data.result;
          this.list_linhvucs.forEach(i=>{ i.text = i.name;})});
        
        
       this.loadItems();
    },
    getTitle(){
      if (this.editMode){
        return 'Cập nhật thông tin khí MBA'
      }else{
        return 'Thêm mới thông tin khí MBA'
      }
    },
    searchInf(){
      this.loadItems();
      if(this.isAdvance) this.isAdvance = false; 
    },
    updateAdvance(){
      this.isAdvance = ! this.isAdvance; 
    },
    
    updateParams(newProps) {
      console.log(newProps);
      this.serverParams = Object.assign({}, this.serverParams, newProps);
       console.log(this.serverParams);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({pageSize: params.currentPerPage});
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      useJwt.post('getMasterData/data_contents',this.serverParams).then(response =>{
        this.items = response.data.result;
        this.totalRecords = response.data.totalRecords;
        this.isLoading = false;
      })
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.editMode=true;
      this.modal_title= "Cập nhật thông tin khí MBA"
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.editMode=false;
      console.log(this.currentItem);
      this.modal_title= "Thêm mới thông tin khí MBA"
      this.$bvModal.show('modal-edit')
    },
    async handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      var validate = await this.$refs.modalEdit.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      console.log(this.currentItem);
      if (!this.editMode){
        useJwt.post('masterData/category_info_mbas', this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi thêm mới ',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.put('masterData/category_info_mbas',this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật ['+error.response.data.result+']!',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá dữ liệu thông tin khí MBA ', {title: 'Please Confirm',size: 'sm',})
      if (res){
        useJwt.delete(`masterData/${this.selected}/${this.currentItem.id}`, {})
        .then(response =>{
          this.loadItems();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá thông tin khí MBA thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá thông tin khí MBA ',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      items: [],
      isAdvance: false, 
      editMode:false,
      list_loaivbs:[],
      list_linhvucs:[],
      list_mba:[],
      currentItem: {attribute:{}},
      fields: [
        
        {
          field: 'id',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'ID văn bản',
          hidden: true,
        },
        {
          field: 'loaivb_name',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'Loại văn bản',
         width:'150px',

        },
        {
          field: 'sohieu_vb',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'Số hiệu văn bản',
          width:'200px',
        },
        {
          field: 'so_congbao',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'Số công báo',
          width:'200px',
        },
        {
          field: 'ngay_banhanh_s',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'Ngày ban hành',
          width:'100px',
        },
        {
          field: 'title',
          filterOptions: {enabled: false, placeholder: ''},
          label: 'Trích yếu văn bản',
        },
        { field: 'action', label: 'Actions',width:'90px', tdClass: 'text-center' }
      ],
      serverParams: {
        columnFilters: {
           
        },
        sort: [],
        page: 1, 
        pageSize: 50
      },
      
      required
    }
  }
}

</script>
<style>
.select2-selection__rendered {
    padding-left: 4px !important;
    padding-right: 23px !important;
    line-height: 22px !important;
}
.select2-search__field{
  padding: 0 !important;
}
.b-form-datepicker.form-control-sm .form-control-sm, .b-form-timepicker.form-control-sm .form-control-sm {
    line-height: 2;
}
.select2-selection--single{
    border: 1px solid #d8d6de;
    border-radius: 1.5rem;
    border-radius: 0.357rem;
    min-height: 32px !important;
    font-size: 0.857rem;
    line-height: 1;
}
.select2-container--default .select2-selection--single{
  border: 1px solid #d8d6de;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMkI5MzhDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.75em !important;
  vertical-align: middle !important;
  font-size: 0.9rem;
}
.vgt-table th{
  text-align: center;
}
.is-invalid .select2-selection--single{
  border: 1px solid #ea5455;
}
.is-invalid .select2-selection--single::before{
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>