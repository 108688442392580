<template>
  <section class="modern-horizontal-wizard">
    <tabs>
      <tab name="Thông tin chung" selected="true">
        <b-form>
          <b-row>
            <b-col
              v-for="(info, index) in generalInfo"
              :key="index"
              :md="info.size"
            >
              <b-form-group :label="info.title" label-class="label-input">
                <Select2
                  v-if="info.inputType === 'select'"
                  class="crud-input"
                  v-model="info.contentData"
                  :options="showOption(info.id)"
                  :placeholder="info.placeholder"
                  :input="showOption(info.id)"
                />
                <b-form-input
                  v-else-if="info.inputType === 'text'"
                  class="crud-input"
                  v-model="info.contentData"
                  :placeholder="info.placeholder"
                  :disabled="!info.editable"
                />
                <b-form-datepicker
                  v-else
                  v-model="info.contentData"
                  locale="vi"
                  @input="showOutDateStatus()"
                  :disabled="!getEnableDatepickerStatus(info.id)"
                  :min="
                    info.id == 'validate'
                      ? getGeneralInfoById('start_date').contentData
                      : null
                  "
                  :placeholder="info.placeholder"
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </tab>
      <tab name="Nội dung văn bản">
        <article>
          <b-row class="editor-content">
            <b-col md="6">
              <ckeditor
                :editor="content.viEditor.editor"
                v-model="content.viEditor.editorContent"
                :config="content.viEditor.editorconfig"
              />
            </b-col>
            <b-col m="6">
              <ckeditor
                :editor="content.enEditor.editor"
                v-model="content.enEditor.editorContent"
                :config="content.enEditor.editorconfig"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              md="3"
              v-for="(uploader, index) in content.fileManager"
              :key="index"
            >
              <b-form-checkbox
                :name="uploader.id"
                v-model="uploader.apiUpload"
                class="no-select upload-title"
              >
                {{ uploader.title }}
              </b-form-checkbox>
              <b-form-input
                v-show="uploader.apiUpload"
                v-model="uploader.apiURL"
              />
              <b-form-file
                v-show="!uploader.apiUpload"
                v-model="uploader.fileUpload"
                class="file-uploader"
                multiple
              />
              <b-container class="uploader-field">
                <b-row
                  v-for="(file, index) in uploader.fileUpload"
                  :key="index"
                  class="upload-item"
                >
                  <b-col md="10"> {{ file.name }} </b-col>
                  <b-col md="2">
                    <b-button
                      variant="danger"
                      class="delete-repeater-btn"
                      @click="removeFileUploaded(uploader.fileUpload, index)"
                    >
                      <feather-icon icon="DeleteIcon"></feather-icon>
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </article>
      </tab>
      <tab name="Nội dung văn bản mở rộng">
        <b-form>
          <b-row class="repeat-header">
            <b-col md="8" class="text-center text-uppercase font-weight-bold">
              Nội dung văn bản
            </b-col>
            <b-col md="2" class="text-center text-uppercase font-weight-bold">
              Loại văn bản
            </b-col>
            <b-col md="2" class="text-right text-uppercase font-weight-bold">
              Chức năng
            </b-col>
          </b-row>
          <b-row
            class="repeated-item"
            v-for="(exInfo, index) in extendedInfo"
            :key="index"
          >
            <b-col md="8">
              <div @click="showDocumentContent(index)">
                <b-form-input
                  v-model="exInfo.docContent"
                  :placeholder="exInfo.placeholder"
                  class="crud-input"
                  @input="searchDocument(exInfo)"
                  autocomplete="off"
                  @keydown.enter.prevent="handleSelectExtDocument(index, 0)"
                />
                <b-card
                  no-body
                  class="dropdown-content"
                  v-if="exInfo.showDropdown"
                >
                  <div
                    v-for="(doc, doc_index) in list_vb_filtered"
                    :key="doc_index"
                    class="dropdown-item no-select"
                    @click="handleSelectExtDocument(index, doc_index)"
                  >
                    {{ doc.name }}
                  </div>
                </b-card>
              </div>
            </b-col>
            <b-col md="3">
              <Select2
                v-model="exInfo.docTypeSelected"
                :options="exInfo.docTypes"
                placeholder="Chọn loại văn bản"
              />
            </b-col>
            <b-col md="1" class="d-flex justify-content-center">
              <b-button
                variant="danger"
                class="delete-repeater-btn"
                @click="removeExtDoc(index)"
              >
                <feather-icon icon="DeleteIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>
          <div class="add-repeater no-select" @click="addNewExtDoc()">
            + Thêm mới loại văn bản
          </div>
        </b-form>
      </tab>
    </tabs>
    <div class="d-flex justify-content-center crud-footer">
      <b-button variant="secondary" class="crud-btn" @click="clearAllData()">
        Xóa trắng
      </b-button>
      <b-button variant="primary" class="crud-btn">Thêm mới</b-button>
    </div>
  </section>
</template>


<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { VBTooltip } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-good-table.scss";
import Select2 from "v-select2-component";
import CrudContent from "./CrudContent.vue";
import Tab from "./customComponent/Tab.vue";
import Tabs from "./customComponent/Tabs.vue";
import Editor from "../../../editor/build/ckeditor";
import CatalogDataManager from "./model/CatalogDataManager";

const dataManager = CatalogDataManager.getInstance();

export default {
  components: {
    vSelect,
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    Select2,
    CrudContent,
    Tab,
    Tabs,
    Editor,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["data"],
  mounted() {
    this.loadInitData();
    this.addNewExtDoc();
    this.loadCurrentEditDocument();
  },
  watch: {
    $route(to, from) {
      this.loadInitData();
    },
  },
  methods: {
    loadInitData() {
      this.loadGeneralDataWithId("catalog_type");
      this.loadGeneralDataWithId("catalog_fields");
      this.loadGeneralDataWithId("catalog_ranges");
      this.loadGeneralDataWithId("catalog_sources");
      this.loadGeneralDataWithId("catalog_career");
      this.loadGeneralDataWithId("catalog_signer");
      this.loadGeneralDataWithId("signer_position");
      this.loadGeneralDataWithId("catalog_status");

      dataManager.loadCatalogFieldDetail(this.serverParams, (data) => {
        this.list_lvuc_ct = data;
      });

      dataManager.loadCatalogIssuedPlace(this.serverParams, (data) => {
        this.list_noibh = data;
      });

      dataManager.loadCatalogIssuedPlaceDetail(this.serverParams, (data) => {
        this.list_noibanhnh_ct = data;
      });

      dataManager.loadDataContents(this.serverParams, (data) => {
        this.list_vb = data;
        this.list_vb_filtered = data;
      });

      this.loadItems();
    },
    loadGeneralDataWithId(id) {
      dataManager.loadCatalogDataWithID(id, this.serverParams, (data) => {
        const field = this.getGeneralInfoById(id);
        field.selection = data;
      });
    },
    getTitle() {
      if (this.editMode) {
        return "Cập nhật thông tin khí MBA";
      } else {
        return "Thêm mới thông tin khí MBA";
      }
    },
    searchInf() {
      this.loadItems();
      if (this.isAdvance) this.isAdvance = false;
    },
    updateAdvance() {
      this.isAdvance = !this.isAdvance;
    },

    updateParams(newProps) {
      console.log(newProps);
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      console.log(this.serverParams);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageSize: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      useJwt
        .post("getMasterData/data_contents", this.serverParams)
        .then((response) => {
          this.items = response.data.result;
          this.totalRecords = response.data.totalRecords;
          this.isLoading = false;
        });
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.editMode = true;
      this.modal_title = "Cập nhật thông tin khí MBA";
      this.$bvModal.show("modal-edit");
    },
    New() {
      this.currentItem = {};
      this.editMode = false;
      console.log(this.currentItem);
      this.modal_title = "Thêm mới thông tin khí MBA";
      this.$bvModal.show("modal-edit");
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      var validate = await this.$refs.modalEdit.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng kiểm tra dữ liệu!",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      console.log(this.currentItem);
      if (!this.editMode) {
        useJwt
          .post("masterData/category_info_mbas", this.currentItem)
          .then((response) => {
            this.loadItems();
            this.$bvModal.hide("modal-edit");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Thêm mới thành công!",
                icon: "CheckSquareIcon",
                variant: "primary",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Lỗi thêm mới ",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        useJwt
          .put("masterData/category_info_mbas", this.currentItem)
          .then((response) => {
            this.loadItems();
            this.$bvModal.hide("modal-edit");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Cập nhật thành công!",
                icon: "CheckSquareIcon",
                variant: "primary",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Lỗi cập nhật [" + error.response.data.result + "]!",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm(
        "Vui lòng xác nhận bạn muốn xoá dữ liệu thông tin khí MBA ",
        { title: "Please Confirm", size: "sm" }
      );
      if (res) {
        useJwt
          .delete(`masterData/${this.selected}/${this.currentItem.id}`, {})
          .then((response) => {
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Xoá thông tin khí MBA thành công!",
                icon: "CheckSquareIcon",
                variant: "primary",
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Lỗi xoá thông tin khí MBA ",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    loadCurrentEditDocument() {
      const id_field = this.getGeneralInfoById("id");
      id_field.contentData = this.data.sohieu_vb ? this.data.sohieu_vb : "";

      const docTypes = this.getGeneralInfoById("catalog_type");
      docTypes.contentData = this.data.loaivb_id ? this.data.loaivb_id : "";

      const createAt = this.getGeneralInfoById("create_at");
      createAt.contentData = this.data.created_at ? this.data.created_at : "";

      const issuePlace = this.getGeneralInfoById("issue_place");
      issuePlace.contentData = this.data.noi_banhanh
        ? this.data.noi_banhanh
        : "";

      const title = this.getGeneralInfoById("title");
      title.contentData = this.data.title ? this.data.title : "";

      const catalog_career = this.getGeneralInfoById("catalog_career");
      catalog_career.contentData = this.data.nganh ? this.data.nganh : "";

      const catalog_fields = this.getGeneralInfoById("catalog_fields");
      catalog_fields.contentData = this.data.linhvuc_id
        ? this.data.linhvuc_id
        : "";

      const field_detail = this.getGeneralInfoById("field_detail");
      field_detail.contentData = this.data.linhvucd_id
        ? this.data.linhvucd_id
        : "";

      const catalog_ranges = this.getGeneralInfoById("catalog_ranges");
      catalog_ranges.contentData = this.data.phamvi ? this.data.phamvi : "";

      const catalog_signer = this.getGeneralInfoById("catalog_signer");
      catalog_signer.contentData = this.data.nguoi_ky ? this.data.nguoi_ky : "";

      const signerPosition = this.getGeneralInfoById("signer_position");
      signerPosition.contentData = this.data.cv_nguoi_ky
        ? this.data.cv_nguoi_ky
        : "";

      const publicDate = this.getGeneralInfoById("public_date");
      publicDate.contentData = this.data.ngay_congbao
        ? this.data.ngay_congbao
        : "";

      const publicNumber = this.getGeneralInfoById("public_number");
      publicNumber.contentData = this.data.so_congbao
        ? this.data.so_congbao
        : "";

      const catalog_sources = this.getGeneralInfoById("catalog_sources");
      catalog_sources.contentData = this.data.nguon_vb
        ? this.data.nguon_vb
        : "";

      const startDate = this.getGeneralInfoById("start_date");
      startDate.contentData = this.data.ngay_hieuluc
        ? this.data.ngay_hieuluc
        : "";

      const validate = this.getGeneralInfoById("validate");
      validate.contentData = this.data.ngay_het_hl ? this.data.ngay_het_hl : "";

      const update = this.getGeneralInfoById("update_at");
      update.contentData = this.data.updated_at ? this.data.updated_at : "";

      const note = this.getGeneralInfoById("note");
      note.contentData = this.data.ghi_chu ? this.data.ghi_chu : "";
    },

    getGeneralInfoById(id) {
      return this.generalInfo.filter((info) => info.id == id)[0];
    },

    getEnableDatepickerStatus(id) {
      if (id != "validate") return true;
      return this.getGeneralInfoById("start_date").contentData !== "";
    },

    showOutDateStatus() {
      let startDateStr = this.getGeneralInfoById("start_date").contentData;
      if (!startDateStr) return;
      let startDate = new Date(startDateStr);

      let validateStr = this.getGeneralInfoById("validate").contentData;
      if (!validateStr) return;
      let validate = new Date(validateStr);

      const catalog_status = this.getGeneralInfoById("catalog_status");
      let currentDate = new Date();
      if (currentDate.getTime() < startDate.getTime())
        catalog_status.contentData = "Chưa có hiệu lực";
      else if (currentDate > validate)
        catalog_status.contentData = "Hết hiệu lực";
      else catalog_status.contentData = "Đang hiệu lực";
    },

    showOption(id) {
      let options = this.getGeneralInfoById(id).selection;
      if (id == "field_detail") {
        const field_id = this.getGeneralInfoById("catalog_fields").contentData;
        options = this.list_lvuc_ct.filter((item) => item.field_id == field_id);
      }

      if (id == "issue_place") {
        const range_id = this.getGeneralInfoById("catalog_ranges").contentData;
        options = this.list_noibh.filter((item) => item.range_id == range_id);
        const issued_place_detail = this.getGeneralInfoById(
          "catalog_wdetail_issued"
        );
      }

      if (id == "catalog_wdetail_issued") {
        // Get option for issue place detail
        const issued_place_detail = this.getGeneralInfoById(
          "catalog_wdetail_issued"
        );
        const id = this.getGeneralInfoById("issue_place").contentData;
        let issue_place = this.list_noibh.filter((item) => item.id == id)[0];
        options = [];
        if (issue_place) {
          if (issue_place.wdetail_id != 0) {
            issued_place_detail.inputType = "select";
            options = this.list_noibanhnh_ct;
          } else {
            options = [issue_place];
          }
        }
      }

      return options;
    },

    searchDocument(extendedInfo) {
      clearTimeout(extendedInfo.searchTimeout);
      extendedInfo.searchTimeout = setTimeout(() => {
        this.searchDocInstance(extendedInfo.docContent);
      }, 200);
    },

    searchDocInstance(keyword) {
      if (keyword == "") {
        this.list_vb_filtered = this.list_vb;
        return;
      }
      this.list_vb_filtered = this.list_vb.filter((vb) =>
        vb.name?.includes(keyword)
      );
    },

    showDocumentContent(index) {
      this.extendedInfo.forEach((extInf, i) => {
        if (i == index) {
          extInf.showDropdown = true;
          this.searchDocInstance(extInf.docContent);
        }
      });
    },

    hideDocumentContent(event) {
      this.extendedInfo.forEach((extInf, i) => {
        extInf.showDropdown = false;
      });
    },

    handleSelectExtDocument(itemIndex, documentIndex) {
      if (this.list_vb_filtered.length == 0) {

      } else {
        const document = this.list_vb_filtered[documentIndex]
        this.extendedInfo[itemIndex].docContent = document.name;
      }
      this.hideDocumentContent();
    },

    addNewExtDoc() {
      var newDoc = {
        ...this.extContent,
      };
      this.extendedInfo.push(newDoc);
    },

    removeExtDoc(index) {
      if (this.extendedInfo.length <= index) return;
      this.extendedInfo.splice(index, 1);
    },

    addNewFileUploaded() {},

    removeFileUploaded(files, index) {
      if (files.length <= index) return;
      files.splice(index, 1);
    },

    clearAllData() {
      this.loadInitData();
      this.addNewExtDoc();
      this.loadCurrentEditDocument();
    },
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      items: [],
      isAdvance: false,
      editMode: false,
      list_noibh: [],
      list_noibanhnh_ct: [],
      list_lvuc_ct: [],
      list_vb: [],
      list_vb_filtered: [],
      currentItem: { attribute: {} },
      generalInfo: [
        {
          id: "id",
          title: "Số hiệu văn bản",
          inputType: "text",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập số hiệu văn bản",
          contentData: "",
        },
        {
          id: "catalog_type",
          title: "Loại văn bản",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Chọn loại văn bản",
          contentData: "",
        },
        {
          id: "create_at",
          title: "Ngày ban hành",
          inputType: "date",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Nhập ngày ban hành",
          contentData: "",
        },
        {
          id: "catalog_career",
          title: "Ngành nghề",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Chọn ngành",
          contentData: "",
        },
        {
          id: "title",
          title: "Trích yếu văn bản",
          inputType: "text",
          size: 12,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập trích yếu văn bản",
          contentData: "",
        },
        {
          id: "update_at",
          title: "Ngày/Văn bản được sửa đổi",
          inputType: "date",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Nhập ngày/văn bản được sửa đổi",
          contentData: "",
        },
        {
          id: "catalog_sources",
          title: "Nguồn văn bản",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Chọn nguồn văn bản",
          contentData: "",
        },
        {
          id: "catalog_fields",
          title: "Lĩnh vực",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Chọn lĩnh vực",
          contentData: "",
        },
        {
          id: "field_detail",
          title: "Lĩnh vực chi tiết",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Chọn lĩnh vực chi tiết",
          contentData: "",
        },
        {
          id: "catalog_signer",
          title: "Người ký",
          inputType: "select",
          size: 3,
          selection: [],
          selected: null,
          placeholder: "Nhập người ký",
          contentData: "",
        },
        {
          id: "signer_position",
          title: "Chức vụ người ký",
          inputType: "select",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Chọn chức vụ người ký",
          contentData: "",
        },
        {
          id: "public_date",
          title: "Ngày công báo",
          inputType: "date",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập ngày công báo",
          contentData: "",
        },
        {
          id: "public_number",
          title: "Số công báo",
          inputType: "text",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Chọn số công báo",
          contentData: "",
        },
        {
          id: "start_date",
          title: "Ngày hiệu lực",
          inputType: "date",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập ngày hiệu lực",
          contentData: "",
        },
        {
          id: "catalog_ranges",
          title: "Phạm vi",
          inputType: "select",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Chọn phạm vi",
          contentData: "",
        },
        {
          id: "issue_place",
          title: "Nơi ban hành",
          inputType: "select",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Chọn nơi ban hành",
          contentData: "",
        },
        {
          id: "catalog_wdetail_issued",
          title: "Nơi ban hành chi tiết",
          inputType: "select",
          size: 3,
          editable: false,
          selection: [],
          selected: null,
          placeholder: "Chọn nơi ban hành",
          contentData: "",
        },
        {
          id: "validate",
          title: "Ngày hết hiệu lực",
          inputType: "date",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập ngày hết hiệu lực",
          contentData: "",
        },
        {
          id: "catalog_status",
          title: "Tình trạng",
          inputType: "select",
          size: 3,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Chọn tình trạng văn bản",
          contentData: "",
        },
        {
          id: "note",
          title: "Ghi chú",
          inputType: "text",
          size: 6,
          editable: true,
          selection: [],
          selected: null,
          placeholder: "Nhập thông tin ghi chú",
          contentData: "",
        }
      ],
      extContent: {
        docContent: "",
        placeholder: "Nội dung văn bản (gọi từ API) - Hint",
        docTypes: [
          { id: 1, name: null, text: "Chọn loại văn bản" },
          { id: 2, name: "vb_huongdan", text: "Văn bản được hướng dẫn" },
          { id: 3, name: "vb_hopnhat", text: "Văn bản được hợp nhất" },
          { id: 4, name: "vb_lienquan", text: "Văn bản liên quan" },
        ],
        searchTimeout: null,
        showDropdown: false,
        docTypeSelected: null,
      },
      extendedInfo: [],
      content: {
        viEditor: {
          editor: Editor,
          editorContent: "",
          editorconfig: {
            placeholder: "Nhập vào đây...",
          },
        },
        enEditor: {
          editor: Editor,
          editorContent: "",
          editorconfig: {
            placeholder: "Start typing here...",
          },
        },
        fileManager: [
          {
            id: "vi_file",
            title: "Bản tiếng Việt",
            apiUpload: false,
            apiURL: "",
            fileUploaded: [],
          },
          {
            id: "en_file",
            title: "Bản tiếng Anh",
            apiUpload: false,
            apiURL: "",
            fileUploaded: [],
          },
          {
            id: "pdf_file",
            title: "Bản PDF",
            apiUpload: false,
            apiURL: "",
            fileUploaded: [],
          },
          {
            id: "public_file",
            title: "Công báo",
            apiUpload: false,
            apiURL: "",
            fileUploaded: [],
          },
        ],
      },
      fields: [
        {
          field: "id",
          filterOptions: { enabled: false, placeholder: "" },
          label: "ID văn bản",
          hidden: true,
        },
        {
          field: "loaivb_id",
          filterOptions: { enabled: false, placeholder: "" },
          label: "Loại văn bản",
          width: "150px",
        },
        {
          field: "sohieu_vb",
          filterOptions: { enabled: false, placeholder: "" },
          label: "Số hiệu văn bản",
          width: "200px",
        },
        {
          field: "noi_banhanh",
          filterOptions: { enabled: false, placeholder: "" },
          label: "Nơi ban hành",
          width: "200px",
        },
        {
          field: "ngay_banhnh",
          filterOptions: { enabled: false, placeholder: "" },
          label: "Ngày ban hành",
          width: "100px",
        },
        {
          field: "title",
          filterOptions: { enabled: false, placeholder: "" },
          label: "Trích yếu văn bản",
        },
        {
          field: "action",
          label: "Actions",
          width: "90px",
          tdClass: "text-center",
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        pageSize: 50,
      },

      required,
    };
  },
};
</script>
<style>
.select2-selection__rendered {
  padding-left: 4px !important;
  padding-right: 23px !important;
  line-height: 22px !important;
}
.select2-search__field {
  padding: 0 !important;
}
.b-form-datepicker.form-control-sm .form-control-sm,
.b-form-timepicker.form-control-sm .form-control-sm {
  line-height: 2;
}
.select2-selection--single {
  border: 1px solid #d8d6de;
  border-radius: 1.5rem;
  border-radius: 0.357rem;
  min-height: 32px !important;
  font-size: 0.857rem;
  line-height: 1;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #d8d6de;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMkI5MzhDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
    center / contain no-repeat;
}
.select2-selection__arrow {
  background: none;
  display: none !important;
}
.vgt-table td,
.vgt-table th {
  padding: 0.25em 0.75em !important;
  vertical-align: middle !important;
  font-size: 0.9rem;
}
.vgt-table th {
  text-align: center;
}
.is-invalid .select2-selection--single {
  border: 1px solid #ea5455;
}
.is-invalid .select2-selection--single::before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
}

.upload-content {
  margin-top: 20px;
}

.label-input {
  color: black;
  font-weight: 500;
}

.crud-input {
  height: 32px !important;
}

.crud-btn {
  margin-right: 15px;
}

.crud-footer {
  border-top: 1px solid #dfdfdf;
  padding: 7px;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  color: white;
  background-color: #979797;
}

.no-select {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.upload-title {
  margin-bottom: 5px;
}

.uploader-field{
  margin-top: 10px;
}

.upload-item{
  margin-bottom: 7px;
}

.repeat-header {
  margin-bottom: 10px;
}

.repeated-item {
  margin-bottom: 11px;
}

.add-repeater {
  display: inline-block;
  margin-bottom: 10px;
}

.add-repeater:hover {
  cursor: pointer;
}

.delete-repeater-btn {
  height: 30px !important;
  width: 37px !important;
  padding: 8px 11px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dropdown-content {
  position: absolute;
  max-height: 240px;
  width: calc(100% - 30px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-item {
  height: 37px;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: ellipsis;

  &:hover {
    cursor: pointer;
  }
}
</style>
